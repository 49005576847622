import { Component, Vue, Prop } from 'vue-property-decorator';
import { Topic as TopicModel } from '@/models/Topic';
import MiFileUpload from '@/components/mi-file-upload/MiFileUpload';
import { cloneDeep } from 'lodash';

@Component<TopicDialog>({})
export default class TopicDialog extends Vue {
  @Prop({ default: null })
  protected selectedTopic!: TopicModel | null;

  protected topic: TopicModel | null = null;

  @Prop({ default: null })
  protected topics!: TopicModel[];

  @Prop({ default: null })
  protected options!: TopicOptions | null;

  protected file: File | null = null;

  protected selectedFile: File | null = null;

  protected acceptedFileTypes: string[] = ['application/pdf'];

  protected isCreatingTopic = false;

  protected isUpdatingTopic = false;

  protected types = [
    {
      name: 'Algemene informatie',
      value: 'generic',
    },
    {
      name: 'Inhoudelijke informatie',
      value: 'contextualized',
    },
  ]

  public mounted() {
    this.initialize();
  }

  protected initialize() {
    if (! this.options) {
      return;
    }

    this.initializeTopic();
  }

  protected initializeTopic(): void {
    this.topic = this.selectedTopic
      ? cloneDeep(this.selectedTopic)
      : new TopicModel();
  }

  protected cancelButtonClicked(): void {
    if (! this.options) {
      return;
    }

    this.options.buttonCancel.action();
  }

  protected async confirmButtonClicked(): Promise<void> {
    if (! this.options) {
      return;
    }

    this.topic && this.topic.id
      ? (this.isUpdatingTopic = true)
      : (this.isCreatingTopic = true);

    this.topic && this.topic.id
      ? await this.options.buttonConfirm.action(this.topic)
      : await this.options.buttonConfirm.action(this.topic, this.file);

    this.topic && this.topic.id
      ? (this.isUpdatingTopic = false)
      : (this.isCreatingTopic = false);
  }

  protected handleItemDropped(file: File) {
    if (! file) {
      return;
    }

    this.file = file;
    const fileUploadComponent = this.$refs.topicUpload as MiFileUpload;
    fileUploadComponent.uploaded();
  }

  protected get topicNameExists(): boolean {
    return this.topics.some((topic: TopicModel): boolean => !! this.topic && !! this.topic.name && this.topic.name === topic.name);
  }

  protected get rules(): Rules {
    return {
      topicNameExists: ! this.topicNameExists || (
        this.options && this.options.ruleTexts.nameExists
          ? this.options && this.options.ruleTexts.nameExists
          : ''
      ),
    };
  }

  protected get confirmButtonDisabled(): boolean {
    if (! this.topic) {
      return true;
    }

    if (this.topic.name === this.selectedTopic?.name) {
      return false;
    }

    if (this.topicNameExists) {
      return true;
    }

    if (this.topic.id) {
      return ! this.topic.name;
    }

    return ! this.topic.name || ! this.file;
  }
}

export type Rule = true | string;
export interface Rules { [key: string]: Rule }

export interface TopicOptions {
  title: string;
  name: string;
  ruleTexts: {
    nameExists: string;
  };
  buttonConfirm: {
    text: string;
    action: Function;
  };
  buttonCancel: {
    text: string;
    action: Function;
  };
}
