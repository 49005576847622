var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-container",
        { attrs: { fluid: "", "grid-list-lg": "" } },
        [
          _c(
            "v-layout",
            { attrs: { wrap: "" } },
            [
              _c(
                "v-flex",
                { attrs: { xs12: "" } },
                [
                  _c(
                    "v-layout",
                    { attrs: { wrap: "" } },
                    [
                      _c(
                        "v-flex",
                        { attrs: { xs12: "" } },
                        [
                          _c(
                            "v-layout",
                            {
                              attrs: { wrap: "", "justify-space-between": "" }
                            },
                            [
                              _c("v-flex", [
                                _c("h2", { staticClass: "elementSubTitle" }, [
                                  _vm._v("Instructies")
                                ])
                              ])
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "elementPanel elementPanel--spaced pb-5"
                            },
                            [
                              _c(
                                "v-layout",
                                { attrs: { wrap: "" } },
                                [
                                  _c(
                                    "v-flex",
                                    { attrs: { shrink: "" } },
                                    [
                                      _c("v-select", {
                                        attrs: {
                                          label: "Rubriek",
                                          clearable: "",
                                          items: _vm.types,
                                          "item-text": "name",
                                          "item-value": "value"
                                        },
                                        model: {
                                          value: _vm.filters.type,
                                          callback: function($$v) {
                                            _vm.$set(_vm.filters, "type", $$v)
                                          },
                                          expression: "filters.type"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              !_vm.isLoadingInstructions
                                ? [
                                    _vm.instructions && _vm.instructions.length
                                      ? _c("DataTable", {
                                          ref: "instructionDataTable",
                                          attrs: {
                                            options: _vm.tableOptions,
                                            visibility: _vm.visibility,
                                            limit: 15
                                          }
                                        })
                                      : _vm._e(),
                                    !_vm.instructions ||
                                    !_vm.instructions.length
                                      ? _c(
                                          "v-layout",
                                          {
                                            staticClass: "item__list",
                                            attrs: { wrap: "", "mb-3": "" }
                                          },
                                          [
                                            _c(
                                              "v-flex",
                                              {
                                                staticClass: "list__item",
                                                attrs: { xs12: "" }
                                              },
                                              [
                                                _c(
                                                  "v-layout",
                                                  { attrs: { wrap: "" } },
                                                  [
                                                    _c(
                                                      "v-flex",
                                                      { attrs: { xs12: "" } },
                                                      [
                                                        _vm._v(
                                                          "Geen instructies gevonden"
                                                        )
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      : _vm._e()
                                  ]
                                : _vm._e(),
                              _vm.isLoadingInstructions
                                ? _c(
                                    "v-layout",
                                    [
                                      _c(
                                        "v-flex",
                                        { attrs: { xs12: "" } },
                                        [
                                          _c("LoaderCard", {
                                            attrs: {
                                              flat: "",
                                              type: "spinner--center",
                                              minHeight: "300px"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              !_vm.isLoadingInstructions &&
                              _vm.user.hasRole(_vm.userRoles.AdminRoles)
                                ? _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        absolute: "",
                                        dark: "",
                                        fab: "",
                                        bottom: "",
                                        right: "",
                                        color: "#a09b1b"
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.addInstructionButtonClicked()
                                        }
                                      }
                                    },
                                    [_c("v-icon", [_vm._v("add")])],
                                    1
                                  )
                                : _vm._e()
                            ],
                            2
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm.instructionDialogOpen
        ? _c(
            "v-dialog",
            {
              attrs: { "max-width": "500", persistent: true },
              model: {
                value: _vm.instructionDialogOpen,
                callback: function($$v) {
                  _vm.instructionDialogOpen = $$v
                },
                expression: "instructionDialogOpen"
              }
            },
            [
              _c("TopicDialog", {
                attrs: {
                  selectedTopic: _vm.selectedInstruction,
                  topics: _vm.instructions,
                  options: _vm.dialogOptionsAddOrEditInstruction
                },
                model: {
                  value: _vm.instructionDialogOpen,
                  callback: function($$v) {
                    _vm.instructionDialogOpen = $$v
                  },
                  expression: "instructionDialogOpen"
                }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }