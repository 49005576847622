import { AxiosError, AxiosResponse } from 'axios';
import { Component, Vue } from 'vue-property-decorator';
import { Topic as TopicModel } from '@/models/Topic';
import ErrorHandler from '@/support/ErrorHandler';
import { Options } from '@/components/mi-dialog/MiDialog';
import TopicDialog from '@/components/dialog/topic-dialog/TopicDialog.vue';
import { TopicOptions } from '@/components/dialog/topic-dialog/TopicDialog';
import { User, userRoles } from '@/models/User';
import { formatDate } from '@/support/String';
import DataTable, { TableVisibility } from '@/components/data-table/DataTable';

@Component<Instruction>({
  components: {
    TopicDialog,
  },
})
export default class Instruction extends Vue {
  public $pageTitle = 'Instructiepagina';

  protected isLoadingInstructions = true;

  protected instructions: TopicModel[] | null = null;

  protected selectedInstruction: TopicModel | null = null;

  protected instructionDialogOpen = false;

  protected visibility: TableVisibility = {
    checkboxes: false,
    title: false,
    search: true,
    limit: false,
  };

  protected filters = {};

  protected userRoles = userRoles;

  protected instructionTypeNames: {[key: string]: string} = {
    generic: 'Algemene informatie',
    contextualized: 'Inhoudelijke informatie',
  };

  protected types = [
    {
      name: 'Algemene informatie',
      value: 'generic',
    },
    {
      name: 'Inhoudelijke informatie',
      value: 'contextualized',
    },
  ];

  public mounted(): void {
    this.initialize();
  }

  protected initialize(): void {
    this.initializeBreadcrumb();
    this.fetchInstructions();
  }

  protected initializeBreadcrumb(): void {
    this.$root.$emit('breadcrumbUpdated', {
      crumb: [{ name: 'Instructiepagina' }],
    });
  }

  protected async fetchInstructions(): Promise<void> {
    this.isLoadingInstructions = true;

    await new TopicModel()
      .all()
      .then((instructions: TopicModel[]) => {
        this.instructions = instructions;
      })
      .catch((error: AxiosError) => {
        this.instructions = null;
        ErrorHandler.network(error);
      });

    this.isLoadingInstructions = false;
  }

  protected async createInstruction(instruction: TopicModel, file: File | null): Promise<void> {
    if (! file) {
      return;
    }

    await new TopicModel()
      .create({
        name: instruction.name,
        file,
        type: instruction.type,
      })
      .then(() => {
        this.reloadInstructions();
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
        this.selectedInstruction = null;
      });
  }

  protected async updateInstruction(instruction: TopicModel): Promise<void> {
    await instruction
      .update()
      .then(() => {
        this.reloadInstructions();
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
        this.selectedInstruction = null;
      });
  }

  protected async deleteInstruction(): Promise<void> {
    if (! this.selectedInstruction || ! this.selectedInstruction.id) {
      return;
    }

    await this.selectedInstruction
      .delete()
      .then((response: AxiosResponse) => {
        this.reloadInstructions();
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected async reloadInstructions(): Promise<void> {
    const dataTable = this.$refs.instructionDataTable as DataTable;
    if (dataTable) {
      dataTable.refresh();
    }
  }

  protected addInstructionButtonClicked(): void {
    this.instructionDialogOpen = true;
  }

  protected editInstructionButtonClicked(instruction: TopicModel): void {
    this.selectedInstruction = instruction;
    this.instructionDialogOpen = true;
  }

  protected deleteInstructionButtonClicked(instruction: TopicModel): void {
    this.selectedInstruction = instruction;
    this.$store.dispatch('openDialog', this.dialogOptionsDeleteInstruction);
  }

  protected get user(): User {
    return this.$store.state.Auth;
  }

  protected get dialogOptionsAddOrEditInstruction(): TopicOptions {
    return {
      title: this.selectedInstruction
        ? `Bewerk: ${this.selectedInstruction.name}`
        : 'Voeg een instructie toe',
      name: 'Titel instructie',
      ruleTexts: {
        nameExists: 'Titel bestaat al',
      },
      buttonConfirm: {
        text: this.selectedInstruction
          ? 'Instructie bewerken'
          : 'Instructie toevoegen',
        action: async (instruction: TopicModel, file: File | null = null): Promise<void> => {
          this.selectedInstruction
            ? await this.updateInstruction(instruction)
            : await this.createInstruction(instruction, file);
          this.instructionDialogOpen = false;
          this.reloadInstructions();
        },
      },
      buttonCancel: {
        text: this.$tc('general.cancel'),
        action: () => {
          this.selectedInstruction = null;
          this.instructionDialogOpen = false;
        },
      },
    };
  }

  protected get dialogOptionsDeleteInstruction(): Options {
    return {
      title: this.$t('dialogOptions.confirmation').toString(),
      text: 'Weet je zeker dat je deze instructie wilt verwijderen?',
      type: 'warning',
      buttons: {
        confirm: {
          color: 'warning',
          text: this.$t('dialogOptions.button.delete').toString(),
          action: () => {
            this.deleteInstruction();
          },
        },
        cancel: {
          text: this.$t('dialogOptions.button.cancel').toString(),
          color: 'text-light',
          action: () => {
            this.$emit('closeDialog');
            this.selectedInstruction = null;
          },
        },
      },
    };
  }

  protected get datatableFilter() {
    return this.filters;
  }

  get tableOptions(): Object {
    return {
      model: new TopicModel().filter(this.datatableFilter),
      name: {
        singular: 'Deadline verzoek',
        plural: 'Deadline verzoeken',
      },
      showTotals: true,
      headers: [
        {
          text: 'Instructie',
          value: 'name',
          action: (instruction: TopicModel) => {
            window.open(instruction && instruction.file ? instruction.file.url : '', '_blank');
          },
          sortable: {
            key: 'name',
            order: 'ASC',
          },
        },
        {
          text: 'Rubriek',
          value: 'type',
          transform: (type: string) => this.instructionTypeNames[type] || '',
          sortable: {
            key: 'type',
            order: 'ASC',
          },
        },
        {
          text: 'Toegevoegd op',
          value: 'updated_at',
          transform: (updated_at: any) => formatDate(updated_at, 'dd-LL-yyyy HH:mm'),
          sortable: {
            key: 'updated_at',
            order: 'DESC',
          },
        },
      ],
      actions: [
        {
          type: 'view',
          label: 'view',
          icon: 'cloud_download',
          action: (datatable: DataTable, instruction: TopicModel) => {
            window.open(instruction && instruction.file ? instruction.file.url : '', '_blank');
          },
        },
        {
          type: 'view',
          label: 'view',
          icon: 'edit',
          visible: () => this.user && this.user.hasRole(this.userRoles.AdminRoles),
          action: (datatable: DataTable, instruction: TopicModel) => {
            this.editInstructionButtonClicked(instruction);
          },
        },
        {
          type: 'view',
          label: 'view',
          icon: 'delete',
          visible: () => this.user && this.user.hasRole(this.userRoles.AdminRoles),
          action: (datatable: DataTable, instruction: TopicModel) => {
            this.deleteInstructionButtonClicked(instruction);
          },
        },
      ],
    };
  }
}
