var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      [
        _c(
          "v-card",
          [
            _vm.isCreatingTopic || _vm.isUpdatingTopic
              ? [
                  _vm.isCreatingTopic || _vm.isUpdatingTopic
                    ? _c("LoaderCard", {
                        attrs: {
                          flat: "",
                          type: "spinner--center",
                          minHeight: "300px"
                        }
                      })
                    : _vm._e()
                ]
              : _vm._e(),
            _vm.topic &&
            _vm.options &&
            !_vm.isCreatingTopic &&
            !_vm.isUpdatingTopic
              ? [
                  _c(
                    "v-card-title",
                    [
                      _c(
                        "v-layout",
                        {
                          staticClass: "elementPanel__header",
                          attrs: { row: "", wrap: "" }
                        },
                        [
                          _c(
                            "v-flex",
                            {
                              staticClass: "header__title",
                              attrs: { sm11: "" }
                            },
                            [_c("h2", [_vm._v(_vm._s(_vm.options.title))])]
                          ),
                          _c(
                            "v-flex",
                            {
                              staticClass: "header__close",
                              attrs: { sm1: "" }
                            },
                            [
                              _c(
                                "v-icon",
                                {
                                  on: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                      return _vm.cancelButtonClicked()
                                    }
                                  }
                                },
                                [_vm._v("cancel")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-card-text",
                    [
                      _vm.isCreatingTopic || _vm.isUpdatingTopic
                        ? _c("LoaderCard", {
                            attrs: {
                              flat: "",
                              type: "spinner--center",
                              minHeight: "250px"
                            }
                          })
                        : _vm._e(),
                      !_vm.isCreatingTopic && !_vm.isUpdatingTopic
                        ? _c(
                            "v-layout",
                            {
                              attrs: {
                                row: "",
                                wrap: "",
                                "justify-space-between": "",
                                "align-baseline": "",
                                "mb-4": ""
                              }
                            },
                            [
                              _c(
                                "v-flex",
                                { attrs: { xs12: "" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      label: _vm.options.name,
                                      rules: [_vm.rules.topicNameExists]
                                    },
                                    model: {
                                      value: _vm.topic.name,
                                      callback: function($$v) {
                                        _vm.$set(_vm.topic, "name", $$v)
                                      },
                                      expression: "topic.name"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "" } },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      label: "Rubriek",
                                      items: _vm.types,
                                      "item-text": "name",
                                      "item-value": "value"
                                    },
                                    model: {
                                      value: _vm.topic.type,
                                      callback: function($$v) {
                                        _vm.$set(_vm.topic, "type", $$v)
                                      },
                                      expression: "topic.type"
                                    }
                                  })
                                ],
                                1
                              ),
                              !_vm.topic.id
                                ? _c(
                                    "v-flex",
                                    { attrs: { xs12: "" } },
                                    [
                                      _c("MiFileUpload", {
                                        ref: "topicUpload",
                                        staticClass: "topicUpload",
                                        attrs: {
                                          clearAfterUpload: false,
                                          multiple: false,
                                          acceptedFileTypes:
                                            _vm.acceptedFileTypes
                                        },
                                        on: {
                                          itemDropped: function($event) {
                                            return _vm.handleItemDropped($event)
                                          }
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _c(
                    "v-card-actions",
                    [
                      _c(
                        "v-layout",
                        { attrs: { "justify-space-between": "", row: "" } },
                        [
                          _c(
                            "MiButton",
                            {
                              attrs: { color: "text-light", outline: "true" },
                              nativeOn: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  return _vm.cancelButtonClicked()
                                }
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.options.buttonCancel.text) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "MiButton",
                            {
                              staticClass: "float--right",
                              attrs: {
                                color: "success",
                                disabled: _vm.confirmButtonDisabled
                              },
                              nativeOn: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  return _vm.confirmButtonClicked()
                                }
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.options.buttonConfirm.text) +
                                  " "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]
              : _vm._e()
          ],
          2
        )
      ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }